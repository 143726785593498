<template>
    <div class="p-col-12 p-card relative p-d-flex corectPading p-flex-column page-container">
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <router-view
            :loading="loading"
            @loadingChange="loadingChange"
        />
    </div>
</template>

<script>
import { getFormById } from '@/api/form';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'index',
    mixins: [requestToastHandler],
    data: () => ({
        home: { to: '/', icon: 'pi pi-home' },
        formTitle: '',
        loading: true
    }),
    computed: {
        formId() {
            return this.$route.params.formId || null;
        }
    },
    methods: {
        async updateFormTitle() {
            if (!this.formId) return;
            try {
                const resultgetFormById = await getFormById(this.formId);
                const form = resultgetFormById.data;
                if (!form) return;
                this.formTitle = form.attributes.name ? form.attributes.name : 'Название не определено';
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        loadingChange(payload = false) {
            this.loading = payload;
        }
    },
    watch: {
        formId(newId) {
            if (newId) {
                this.updateFormTitle();
            }
        },
    },
    mounted() {
        if (this.formId) {
            this.updateFormTitle();
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.loading = true;
        next();
    }
};
</script>

<style lang='scss' scoped>
.corectPading {
    padding: 20px 16px !important;
}
.p-card {
    box-shadow: none !important;
}
.p-datatable-loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}
.p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
.pi-spinner {
    font-size: 2rem;
}
</style>
